import './App.css';

import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';

import React, { useState, useEffect, Suspense, lazy }  from 'react';
import styled from "styled-components";
import ReactGA from "react-ga4";
import { HelmetProvider } from 'react-helmet-async';

import Header from './components/PageHeader';
import FixedMenuBottom from './FixedMenuBottom';

import { useAuth, AuthProvider } from './context/AuthContext';
import { PageTrackProvider } from './context/PageTrackContext';
import CenteredLoading from './components/CenteredLoading';
import { usePageTrack } from './context/PageTrackContext'; // usePageTrack 컨텍스트 가져오기

// const FixedMenuBottom = lazy(() => import('./FixedMenuBottom'));
// Lazy 로드할 컴포넌트들

const HomePage = lazy(() => import('./containers/public/home/HomePage'));
const Step1CenterFinderForm = lazy(() => import('./containers/customer/centerFinder/Step1CenterFinderForm'));
const Step2CenterFinderForm = lazy(() => import('./containers/customer/centerFinder/Step2CenterFinderForm'));
const Step3CenterFinderForm = lazy(() => import('./containers/customer/centerFinder/Step3CenterFinderForm'));
const Step4CenterFinderForm = lazy(() => import('./containers/customer/centerFinder/Step4CenterFinderForm'));
const Step5CenterFinderForm = lazy(() => import('./containers/customer/centerFinder/Step5CenterFinderForm'));
const Step6CenterFinderForm = lazy(() => import('./containers/customer/centerFinder/Step6CenterFinderForm'));
const CenterFinderFormConfirm = lazy(() => import('./containers/customer/centerFinder/CenterFinderFormConfirm'));

const PrivacyPolicy = lazy(() => import('./containers/customer/signin/PrivacyPolicy'));
const LoginCustomerPage = lazy(() => import('./containers/customer/login/LoginCustomerPage'));
const LoginPartnerPage = lazy(() => import('./containers/partner/login/LoginPartnerPage'));
const LoginCustomerCallbackPage = lazy(() => import('./containers/customer/login/LoginCustomerCallbackPage'));
const LoginPartnerCallbackPage = lazy(() => import('./containers/partner/login/LoginPartnerCallbackPage'));

const SigninCustomer = lazy(() => import('./containers/customer/signin/SigninCustomer'));
const Step1SigninPartner = lazy(() => import('./containers/partner/signin/Step1SigninPartner'));
const Step2SigninPartner = lazy(() => import('./containers/partner/signin/Step2SigninPartner'));
const SigninCompleteCustomer = lazy(() => import('./containers/customer/signin/SigninCompleteCustomer'));
const SigninCompletePartner = lazy(() => import('./containers/partner/signin/SigninCompletePartner'));
const WaitActivationPartner = lazy(() => import('./containers/partner/signin/WaitActivationPartner'));
const NavigateSigninPartner = lazy(() => import('./containers/partner/signin/NavigateSigninPartner'));

const CustomerRequest = lazy(() => import('./containers/customer/quotes/requests/CustomerRequest'));
const PartnerRequest = lazy(() => import('./containers/partner/quotes/PartnerRequestsList'));
const QuotesResponses = lazy(() => import('./containers/customer/quotes/responses/QuotesResponses'));
const PartnerRequestQuotes = lazy(() => import('./containers/partner/quotes/PartnerRequestQuotes'));
const CustomerQuotesDetail = lazy(() => import('./containers/customer/quotes/responses/CustomerQuotesDetail'));

const CustomerRequestComplete = lazy(() => import('./containers/customer/quotes/requests/CustomerRequestComplete'));
const PartnerQuoteComplete = lazy(() => import('./containers/partner/quotes/PartnerQuoteComplete'));



const Reviews = lazy(() => import('./Reviews'));
const Detail = lazy(() => import('./Detail'));
const ScholarshipFinder = lazy(() => import('./ScholarshipFinder'));
const ScholarshipFinderResult = lazy(() => import('./ScholarshipFinderResult'));
const ScholarshipFinderProcessing = lazy(() => import('./ScholarshipFinderProcessing'));

const UniversityList = lazy(() => import('./UniversityList'));
const StudyAbroadCenterList = lazy(() => import('./StudyAbroadCenterList'));
const StudyAbroadCenterDetail = lazy(() => import('./StudyAbroadCenterDetail'));
const StudyAbroadCenterReviews = lazy(() => import('./StudyAbroadCenterReviews'));
const WriteReview = lazy(() => import('./WriteReview'));
const Footer = lazy(() => import('./Footer'));
const CommunitySection = lazy(() => import('./CommunitySection'));

let Wrapper = styled.div`
  width: 100%;  
  height: 100%;  
  max-width: 480px;  
  margin: 0 auto;
`;

let HomeButton = styled.button`
    position: relative;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    line-height: 1.25;
    border-radius: 28px;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
    height: 53px;
    font-size: 20px;
    padding: 0px 14px;
    background-color: #2b96ed;
    color: #ffffff;
    font-weight: 700;
    border:none;
    opacity: 1; 
    pointer-events: auto; /* pointer-events 속성 추가 */


    transition: opacity 0.25s ease-in-out; /* opacity 속성만 transition 적용 */

    outline: none;
  `;

let HomeWrpper = styled.div`
position: relative;
left: 0px;
right: 0px;
top : 20px;
bottom: 20px;
margin: 0px auto;
padding: 0px 20px;
max-width: 480px;

`;
const MarginDiv = styled.div`
  height: 40px;
`;
// const gaTrackingId = process.env.REACT_APP_GA_TRACKING_ID; // 환경 변수에 저장된 추적ID 가져오기
ReactGA.initialize("G-CNLDWW5C45"); // react-ga 초기화 및 debug 사용


const ProtectedRoute = ({ children, pageType, redirectUrl}) => {
  const { authState } = useAuth(); 
  const currentLocation = useLocation();
  const { previousPage } = usePageTrack(); // 이전 페이지 경로 가져오기

  const fullPath = currentLocation.pathname + currentLocation.search;

  redirectUrl = redirectUrl || fullPath;

  // 로그인하지 않은 경우
  if (!authState) {
    const redirectPath = pageType === "PartnerPage" ? 
      `/partner/login?redirectUrl=${encodeURIComponent(redirectUrl)}` : 
      `/login?redirectUrl=${encodeURIComponent(redirectUrl)}`;
    return <Navigate to={redirectPath} state={{ from: previousPage,  redirected: true }} />;
  }

  // 로그인 한 경우
  const isCustomer = authState.user.user_type === "Customer";
  const isPartner = authState.user.user_type === "Partner";

  
  // 파트너 유저이지만 상태가 'active'가 아닌 경우, 파트너 페이지로 접근한 경우
  if (isPartner && authState.errorCode === "INACTIVE_PARTNER" && pageType === "PartnerPage") {
    // 여기서는 홈으로 리다이렉트 하지만 다른 처리를 할 수도 있습니다.
    return <Navigate to="/partner/wait_activation" />;
  }

  // Customer 유저가 파트너 페이지 접근한 경우
  if (isCustomer && pageType === "PartnerPage") { 
    return <Navigate to="/partner/navigate_signin" />;
  }


  if (isCustomer && pageType === "CustomerLoginPage") { 
    return <Navigate to="/requests" state={{ from: previousPage,  redirected: true }}  />;
  }

  if (isPartner && (pageType === "CustomerLoginPage" || pageType === "PartnerLoginPage")) {
    return <Navigate to="/partner/requests" state={{ from: previousPage, redirected: true }}  />;
  }

  return children;

};


function App() { 

  const navigate = useNavigate();
  
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);


  // localhost는 기록하지 않음
  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize("G-CNLDWW5C45");
      setInitialized(true);
    }
  }, []);

  // location 변경 감지시 pageview 이벤트 전송
  useEffect(() => {
    if (initialized) {
      ReactGA.set({ page: location.pathname });
      // ReactGA.send("pageview");
      ReactGA.send({hitType: "pageview", path: location.pathname, location: location.pathname, title: location.pathname}); 
    }
  }, [initialized, location]);


  return (
    <PageTrackProvider>
      <AuthProvider>
        <Wrapper>
        <HelmetProvider>
        <MarginDiv/>

        <Header/>
        <Suspense fallback={
            <CenteredLoading />
          }>

        <Routes>  
            {/* 메인 */}
            <Route path="/" element={
              <>
              <HomePage/>
              <FixedMenuBottom />
              <Footer />
            </> 
            }/>

            {/* 개인정보처리방침 */}
            <Route path="/privacy-policy" element={
              <>
              <PrivacyPolicy/>
              <Footer />
            </> 
            }/>

            {/* 후기 */}
            
            <Route path="/reviews/:id" element={
              <ProtectedRoute pageType="CustomerPage">
              <Reviews/>
              </ProtectedRoute>
            } />

            {/* 대학교 찾기 */}
            <Route path="/university" element={
                <>
                <UniversityList />
                <FixedMenuBottom />
                <CommunitySection />
                <Footer />
                </>
            
            }/>

            {/* 상세 */}
            <Route path="/university/detail/:id" element={
              <>
              <Detail/>
              </>
            } />

            {/* 유학센터 찾기 */}
            <Route path="/studyabroadcenter" element={
              <>
              <StudyAbroadCenterList />
              <FixedMenuBottom />
              <CommunitySection />
              <Footer />
              </>
              
            }/>

            {/* 유학센터 상세 */}
            <Route path="/studyabroadcenter/detail/:id" element={
              <>
              <StudyAbroadCenterDetail/>
              </>} />

            {/* 유학센터 리뷰 */}
            <Route path="/studyabroadcenter/reviews/:id" element={
              <ProtectedRoute pageType="CustomerPage">
              <StudyAbroadCenterReviews/>
              </ProtectedRoute>
            } />

            {/* 장학금 찾기 */}
            <Route path="/scholarshipfinder" element={
                <>
                <ScholarshipFinder
                title='나에게 맞는 장학금 찾기'
                subTitle='점수를 입력하면 장학금을 알려드릴게요'
                />
                </>
            }/>
            <Route path="/finding" element={
              <>
              <ScholarshipFinderProcessing/>
              </>
              
            }/>

              <Route path="/result" element={
                <ProtectedRoute pageType="CustomerPage">
                  <ScholarshipFinderResult
                  title='장학금 찾기 결과'
                  subTitle='이렇게 나왔어요!'
                  />
                </ProtectedRoute>
                    
            }/>
            {/* 리뷰쓰기 */}
            <Route path="/write_review" element={
                <>
                  <WriteReview/>
                  <FixedMenuBottom />
                </>
                    
            }/>

          {/* 내게 맞는 유학센터 찾기 */}
          <Route path="/center_finder/1" element={
              <>
                <Step1CenterFinderForm/> 
              </>
          }/>
          {/* 내게 맞는 유학센터 찾기 */}
          <Route path="/center_finder/2" element={
              <>
                <Step2CenterFinderForm/> 
              </>
          }/>
          {/* 내게 맞는 유학센터 찾기 */}
          <Route path="/center_finder/3" element={
              <>
                <Step3CenterFinderForm/> 
              </>
          }/>
          {/* 내게 맞는 유학센터 찾기 */}
          <Route path="/center_finder/4" element={
              <>
                <Step4CenterFinderForm/> 
              </>
          }/>
          {/* 내게 맞는 유학센터 찾기 */}
          <Route path="/center_finder/5" element={
              <>
                <Step5CenterFinderForm/> 
              </>
          }/>

          {/* 내게 맞는 유학센터 찾기 */}
          
          <Route path="/center_finder/6" element={
              <ProtectedRoute pageType="CustomerPage">
                <Step6CenterFinderForm/> 
              </ProtectedRoute>
            }/>

          {/* 내게 맞는 유학센터 찾기 */}
          <Route path="/center_finder/confirm" element={
              <>
                <CenterFinderFormConfirm/> 
              </>
            }/>


        {/* 로그인 페이지 */}    
        <Route path="/login" element={
            // <ProtectedRoute pageType="CustomerLoginPage">
            <LoginCustomerPage />
            // </ProtectedRoute>
          }/>

        {/* 로그인 콜백 페이지 */}
        <Route path="/login/callback" element={
          <>
            <LoginCustomerCallbackPage />
          </>
        }/>

        {/* 고객 회원가입 페이지 */}    
        <Route path="/signin" element={
            <SigninCustomer />  
          }/>

        {/* 고객 회원가입 완료 페이지 */}    
        <Route path="/signin/complete" element={
            <>
            <SigninCompleteCustomer />  
          </> 
          }/>

        {/* 파트너 로그인 페이지 */}    
        <Route path="/partner/login" element={
          <>
            <LoginPartnerPage />  
          </>
          }/>

        {/* 파트너 로그인 콜백 페이지 */}
        <Route path="/partner/login/callback" element={
          <>
            <LoginPartnerCallbackPage />
          </>
        }/>

        {/* 파트너 회원가입 페이지 */}    
        <Route path="/partner/signin/1" element={
            <>
            <Step1SigninPartner />  
          </> 
          }/>

        {/* 파트너 회원가입 페이지 */}    
        <Route path="/partner/signin/2" element={
            <>
            <Step2SigninPartner />  
          </> 
          }/>

        {/* 파트너 회원가입 완료 페이지 */}    
        <Route path="/partner/signin/complete" element={
            <>
            <SigninCompletePartner />  
          </> 
          }/>

        {/* 학생 견적리스트 페이지 */}    
        <Route path="/requests" element={
          <ProtectedRoute pageType="CustomerPage">
            <CustomerRequest />  
            <FixedMenuBottom />
          </ProtectedRoute>
        }/>

        {/* 센터 견적리스트 페이지 */}    
        <Route path="/partner/requests" element={
          <ProtectedRoute pageType="PartnerPage">
            <PartnerRequest/>  
            <FixedMenuBottom />
          </ProtectedRoute> 
          }/>

        {/* 학생 견적 페이지 */}    
        <Route path="/requests/:request_id/quotes" element={
           <ProtectedRoute pageType="CustomerPage">
             <QuotesResponses/>  
          </ProtectedRoute> 
        }/>

        {/* 센터 견적 페이지 */}    
        <Route path="/partner/requests/:request_id/quote" element={
           <ProtectedRoute pageType="PartnerPage">
            <PartnerRequestQuotes/>  
          </ProtectedRoute> 
          }/>       

        {/* 학생 견적 디테일 페이지 */}    
        <Route path="/requests/:request_id/quotes/:quote_id" element={
            <ProtectedRoute pageType="CustomerPage">
              <CustomerQuotesDetail/>  
            </ProtectedRoute> 
          }/>

        
        {/* 학생 견적 신청 완료 페이지 */}    
        <Route path="/requests/complete" element={
            <>
            <CustomerRequestComplete/>  
          </> 
          }/> 

        {/* 파트너 견적 제출 완료 페이지 */}    
        <Route path="/partner/quote/complete" element={
            <>
            <PartnerQuoteComplete/>  
          </> 
        }/> 

         {/* 파트너 승인 대기 페이지 */}    
         <Route path="/partner/wait_activation" element={
            <>
            <WaitActivationPartner/>  
          </> 
        }/> 

         {/* 파트너 계정 로그인 안내 페이지 */}    
         <Route path="/partner/navigate_signin" element={
            <>
            <NavigateSigninPartner/>  
          </> 
        }/> 

        {/* 404 */}
        <Route path="*" element={
          <div>
            <HomeWrpper>
              <HomeButton onClick={()=>{ navigate('/')}}>Home</HomeButton>
            </HomeWrpper>
          </div>
        } />

        </Routes>
        </Suspense>
        <MarginDiv/>
        </HelmetProvider>
      </Wrapper>
      </AuthProvider>
    </PageTrackProvider>
  );
}

export default App;


