import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageTrackContext = createContext();
// PageTrackContext.js

export const PageTrackProvider = ({ children }) => {
    const [pageHistory, setPageHistory] = useState([]);
    const [redirected, setRedirected] = useState(false); // 리다이렉션 상태 추가
    const location = useLocation();
    
    useEffect(() => {
      setPageHistory(prevHistory => [...prevHistory.slice(-1), location.pathname]);
      setRedirected(location.state?.redirected || false); // 리다이렉션 여부 업데이트
    }, [location]);
  
    // console.log(location.state?.from);
    let previousPage = location.state?.from ? location.state.from : pageHistory[pageHistory.length - 1];

    // console.log(previousPage);

    return (
      <PageTrackContext.Provider value={{ previousPage, redirected }}>
        {children}
      </PageTrackContext.Provider>
    );
  };
  
export const usePageTrack = () => useContext(PageTrackContext);
