

function getUserId() {
  const userId = localStorage.getItem('authInfo') ? parseInt(JSON.parse(localStorage.getItem('authInfo')).user.user_id) : "";
  return userId;
}

function getUserType() {
  const userType = localStorage.getItem('authInfo') ? JSON.parse(localStorage.getItem('authInfo')).user.user_type : "";
  return userType;
}


function sortCenterByReviewsCount(centerList, reviews) {
    return centerList.sort((a, b) => {
        // center가 존재하는지 확인하고 리뷰 수를 계산
        const getValidReviewsCount = (centerId) => {
          const reviewsCount = reviews.filter(item => item.center_id === centerId).length;
          // university_id가 없는 경우, -1을 반환하여 리스트의 맨 뒤로 정렬
        //   if (reviewsCount === 0) return -1;
          
          return reviewsCount;
        };
  
        const reviewsCountA = getValidReviewsCount(a.center_id);
        const reviewsCountB = getValidReviewsCount(b.center_id);
  
        return reviewsCountB - reviewsCountA;
    });
  }

function formatCreatedAt(dateString) {
    const timestamp = parseInt(dateString, 10);
    const date = new Date(timestamp);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `ngày ${parseInt(day)} tháng ${parseInt(month)}`;
  }

  function keyMappings() {
    return {
      course_type: 'Dự định du học',
      major: 'Chuyên ngành mong muốn',
      university_name: 'Trường đại học mong muốn',
      birth_year: 'Năm sinh',
      gpa: 'Điểm trung bình học bạ 3 năm THPT',
      topik_score: 'Điểm Topik',
      hometown: 'Quê quán',
      parent_occupation: 'Nghề nghiệp của phụ huynh',
      parent_income: 'Thu nhập của phụ huynh',
      parent_assets: 'Tài sản của phụ huynh',
      bank_statement_request: 'Tư vấn Chứng minh tài chính ',
      korean_lesson_request: 'Đăng ký lớp học tiếng Hàn',
      flight_pickup_request: 'Đăng ký Vé máy bay & Đưa đón sân bay',
      additional_questions: 'Câu hỏi thêm',
      // 필요한 매핑 추가
    };}

export {sortCenterByReviewsCount, getUserId, getUserType, formatCreatedAt, keyMappings}
