
import React, {useState, useEffect} from 'react';
import styled from "styled-components";
import { Link, useLocation } from 'react-router-dom';
import { getUserType } from './function/Functions';

const NavSection = styled.nav`
        width: 100%;
        height: calc(70px + max(env(safe-area-inset-bottom),0px));
        position: fixed;
        bottom: 0;
        max-width: 480px;
        z-index: 101;
        background-color: white;
        overflow: hidden
        box-sizing: border-box;
        border-top: 1px solid rgb(221, 221, 221) ;
        display: flex ;
        align-items: center;
    contain: paint ;
    background: #ffffff ;
`;


const SectionUl = styled.ul`
    -webkit-box-pack: center ;
    -ms-flex-pack: center ;
    -webkit-box-align: start ;
    -ms-flex-align: start ;
    -webkit-flex: 1 0 auto ;
    -ms-flex: 1 0 auto ;
    align-items: flex-start ;
    display: -moz-box ;
    display: -ms-flexbox ;
    display: -webkit-flex ;
    display: flex ;
    -webkit-justify-content: center ;
    justify-content: center ;
    margin: 0 auto ;
    padding: 0;
`;


const IconLink = styled(Link)`
    display: -webkit-box ;
    display: -moz-box ;
    display: -ms-flexbox ;
    display: -webkit-flex ;
    display: flex ;
    flex: 1 1 0 ;
    flex-direction: column ;
    align-items: center ;
    cursor: pointer ;
    background-color: transparent;
    text-align: center ;
    text-decoration: none ;
    border: 0px ;
    min-width: 0px ;
    overflow-wrap: break-word ;
    hyphens: auto ;
    padding: 0 2px ;
    color: ${(props) => (props.selected ? '#2b96ed' : '#141719')}; // 선택된 경우 색상 변경
`;
const IconContainer = styled.div`
    color: ${(props) => (props.selected ? '#2b96ed' : '#141719')}; // 선택된 경우 색상 변경
      text-decoration: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center ;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 2px ;
    position: relative;

`;

const IconSVG = styled.svg`
    display: block;
    fill: none;
    height: 24px;
    width: 24px;
    stroke: currentcolor;
    stroke-width: 2.66667;
    overflow: visible;
    padding: 0 2px ;

`;

const TextContainer = styled.div`
    overflow: clip ;
    font-size: 10px ;
    line-height: 12px ;
    overflow: hidden ;
    text-overflow: ellipsis ;
    display: -webkit-box ;
    -webkit-line-clamp: 2 ;
    -webkit-box-orient: vertical ;
    overflow-wrap: break-word ;
    font-weight: 500;
    color: ${(props) => (props.selected ? '#2b96ed' : '#141719')}; // 선택된 경우 색상 변경
    letter-spacing: 0.32px ;
`;

// 2b96ed
function FixedMenuBottom() {
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [requestLink, setRequestLink] = useState("/requests");
    const location = useLocation();
    const pathName = location.pathname;
    

    useEffect(() => {
        async function fetchUserType() {
            let fetchedUserType = getUserType();
            if (fetchedUserType === "Partner") {
                setRequestLink("/partner/requests");
            } else if (fetchedUserType === "Customer") {
                setRequestLink("/requests");
            }
        }
        fetchUserType();
    }, [selectedIcon]);

    useEffect(() => {
        let iconSelector;
        if (pathName === '/') {
            iconSelector = 'svg1';
        } else if (pathName === '/studyabroadcenter') {
            iconSelector = 'svg2';
        } else if (pathName === '/university') {
            iconSelector = 'svg3';
        }
        else if (pathName === '/requests' ||pathName === '/partner/requests' )  {
            iconSelector = 'svg5';
        }
        else if (pathName === '/write_review') {
            iconSelector = 'svg5';
        }
        setSelectedIcon(iconSelector);
    }, [pathName]);
    
    const handleSvgClick = (iconName) => {
        setSelectedIcon(iconName); // 다른 아이콘을 클릭하면 선택을 변경합니다.
    };
    
  
    return (
        <NavSection>
        <SectionUl> 
                <IconLink
                 to="/"
                    selected={selectedIcon === 'svg1'}
                    onClick={() => handleSvgClick('svg1')}>
                    <IconContainer selected={selectedIcon === 'svg1'}>
                        <IconSVG xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512"
                            >
                            <path fill="currentColor" d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"/>
                        </IconSVG>
                    </IconContainer>
                    <TextContainer selected={selectedIcon === 'svg1'}>
                        Home
                    </TextContainer>
                </IconLink>
               
                <IconLink to="/studyabroadcenter"
                    onClick={() => handleSvgClick('svg2')}>
                    <IconContainer selected={selectedIcon === 'svg2'} >
                        <IconSVG xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512">
                            <path fill="currentColor" d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16z"/>
                        </IconSVG>
                    </IconContainer>
                    <TextContainer selected={selectedIcon === 'svg2'}>
                        Tìm Trung Tâm Du Học
                    </TextContainer>
                </IconLink>
                {/* <IconLink to="/write_review"
                    onClick={() => handleSvgClick('svg5')}
                >
                    <IconContainer selected={selectedIcon === 'svg5'} >
                        <IconSVG xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/>
                        </IconSVG>
                    </IconContainer>
                    <TextContainer selected={selectedIcon === 'svg5'}>
                        Write review
                    </TextContainer>
                </IconLink> */}

                <IconLink to={requestLink}
                    onClick={() => handleSvgClick('svg5')}
                >
                    <IconContainer selected={selectedIcon === 'svg5'} >
                        <IconSVG xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/>
                        </IconSVG>
                    </IconContainer>
                    <TextContainer selected={selectedIcon === 'svg5'}>
                    Yêu cầu báo giá
                    </TextContainer>
                </IconLink>

                <IconLink to="/university"
                    onClick={() => handleSvgClick('svg3')}
                >
                    <IconContainer selected={selectedIcon === 'svg3'} >
                        <IconSVG xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512">
                            <path fill="currentColor" d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z"/>
                        </IconSVG>
                    </IconContainer>
                    <TextContainer selected={selectedIcon === 'svg3'}>
                        Tìm Đại học
                    </TextContainer>
                </IconLink>
                {/* <IconLink to="/scholarshipfinder"
                    onClick={() => handleSvgClick('svg4')}
                >
                    <IconContainer selected={selectedIcon === 'svg4'} >
                        <IconSVG xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM228 104c0-11-9-20-20-20s-20 9-20 20v14c-7.6 1.7-15.2 4.4-22.2 8.5c-13.9 8.3-25.9 22.8-25.8 43.9c.1 20.3 12 33.1 24.7 40.7c11 6.6 24.7 10.8 35.6 14l1.7 .5c12.6 3.8 21.8 6.8 28 10.7c5.1 3.2 5.8 5.4 5.9 8.2c.1 5-1.8 8-5.9 10.5c-5 3.1-12.9 5-21.4 4.7c-11.1-.4-21.5-3.9-35.1-8.5c-2.3-.8-4.7-1.6-7.2-2.4c-10.5-3.5-21.8 2.2-25.3 12.6s2.2 21.8 12.6 25.3c1.9 .6 4 1.3 6.1 2.1l0 0 0 0c8.3 2.9 17.9 6.2 28.2 8.4V312c0 11 9 20 20 20s20-9 20-20V298.2c8-1.7 16-4.5 23.2-9c14.3-8.9 25.1-24.1 24.8-45c-.3-20.3-11.7-33.4-24.6-41.6c-11.5-7.2-25.9-11.6-37.1-15l-.7-.2c-12.8-3.9-21.9-6.7-28.3-10.5c-5.2-3.1-5.3-4.9-5.3-6.7c0-3.7 1.4-6.5 6.2-9.3c5.4-3.2 13.6-5.1 21.5-5c9.6 .1 20.2 2.2 31.2 5.2c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-6.5-1.7-13.7-3.4-21.1-4.7V104z"/>
                        </IconSVG>
                    </IconContainer>
                    <TextContainer selected={selectedIcon === 'svg4'}>
                        Tìm học bổng 
                    </TextContainer>
                </IconLink> */}
             
        </SectionUl> 
    </NavSection>
    )
}

export default FixedMenuBottom;



