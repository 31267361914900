import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from "styled-components";
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { usePageTrack } from '../context/PageTrackContext';

const HeaderWrapper = styled.div`
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 480px;
    height: 52px;
    z-index: 1350;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 0.5px 0px, rgba(0, 0, 0, 0.03) 0px 1px 2px, rgba(0, 0, 0, 0.03) 0px 2px 4px;
    box-sizing: border-box;
`;

const HeaderContainer = styled.div`
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 52px;
    
    border: none;
    overflow: hidden;

    background-color: #fff;
    transition: background-color 0.3s ease-in-out;
`;

const HeaderButtonContainer = styled.div`
position: absolute;
top: 50%;
left: 0;
transform: translate(0, -50%);
`;


const LogoFont = styled.div`

font-size: 20px;
font-weight: 900;
line-height: 135%;
color: #51ABF3;

`;

const LogoLink = styled(Link)`
display: block;
padding: 12px 6px 24px;
text-decoration: none;
// color: rgb(20, 23, 25);

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: 900;
    line-height: 135%;
    all: unset;
    cursor: pointer;
`;

const HeaderBackButton = styled.div`
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
`;


const LoginButtonContainer = styled.div`
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
`;

const LoginButton = styled.div`
    cursor: pointer;
    background-color: #51ABF3;

    color: #fff;
    font-weight: 700;
    padding: 8px 16px;
    border-radius: 8px;
`;

const LogoutButton = styled.div`
    cursor: pointer;
    background-color: unset;
    color: #000;
    font-weight: 300;
    font-size: 12px;
    padding: 8px 16px;
    border-radius: 8px;
    text-decoration: underline;
`; 

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { previousPage, redirected } = usePageTrack();
    const { authState, logout } = useAuth();
    const [userId, setUserId] = useState(authState?.user?.user_id || "");

    useEffect(() => {
        if (authState?.user?.user_id !== userId) {
            setUserId(authState?.user?.user_id || "");
        }
    }, [authState, userId]);

    const handleGoBack = () => {
        console.log(previousPage);
        if (redirected) {
            navigate('/');
        } else {
            navigate(-1);
        } 
    };

    const handleLogin = () => {
        navigate('/login');
    };

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    // 헤더를 숨길 URL 경로들
    const hideBackButtonPaths = [
        "/",
        "/login",
        "/studyabroadcenter",
        "/requests",
        "/partner/requests",
        "/university",
        "/login",
        "/partner/login"
    ];

    // 현재 경로가 hideHeaderPaths에 속하는지 확인
    const shouldHideBackButton = hideBackButtonPaths.includes(location.pathname);

    // if (shouldHideBackButton) {
    //     return null; // 헤더 숨김
    // }

    return (
        <HeaderWrapper>
            <HeaderContainer>
                {/* 조건에 따라 다른 버튼 또는 링크 렌더링 */}
                {(!shouldHideBackButton) && location.pathname !== '/' && (
                    <HeaderButtonContainer>
                        <HeaderBackButton onClick={handleGoBack}>
                             {/* SVG 및 기타 컴포넌트 */}
                             <svg fill="none" height="24" stroke="#2b2b33" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><title>뒤로가기</title><path d="M16 20L8 12L16 4" stroke="#2B2B33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path></svg>
                        </HeaderBackButton>
                    </HeaderButtonContainer>
                )}
                <LogoLink to='/'>
                    <LogoFont>:DD24</LogoFont>
                </LogoLink>
                {userId === "" ? (
                    <LoginButtonContainer>
                        <LoginButton onClick={handleLogin}>
                            Đăng nhập
                        </LoginButton>
                    </LoginButtonContainer>
                ) : (
                    <LoginButtonContainer>
                        <LogoutButton onClick={handleLogout}>
                            Đăng xuất
                        </LogoutButton>
                    </LoginButtonContainer>
                )}
            </HeaderContainer>
        </HeaderWrapper>
    );
};

export default React.memo(Header);
