// AuthContext.js
import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext({
  authState: null,
  login: () => {},
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState(() => {
    const storedAuthInfo = localStorage.getItem('authInfo');
    return storedAuthInfo ? JSON.parse(storedAuthInfo) : null;
  });

  const login = (userData) => {
    localStorage.setItem('authInfo', JSON.stringify(userData));
    setAuthState(userData);
  };

  const logout = () => {
    localStorage.removeItem('authInfo');
    setAuthState(null);
  };

  return (
    <AuthContext.Provider value={{ authState, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
  
export const useAuth = () => useContext(AuthContext);
  