import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from 'history'; // Import createBrowserHistory
import { GoogleOAuthProvider } from '@react-oauth/google'; // 여기에 추가

import ScrollTop from './ScrollTop';


import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client'; // Apollo Client 관련 패키지 추가
import { setContext } from '@apollo/client/link/context';

const customHistory = createBrowserHistory();

// Facebook 로그인 후 URL 정리 스크립트
if (window.location.hash && window.location.hash === '#_=_') {
  window.location.hash = '';
  customHistory.push('', document.title, window.location.pathname);
}


const httpLink = createHttpLink({
  // uri: 'https://dd24backend-5ba832abdf96.herokuapp.com/graphql', // 여기에 실제 GraphQL 서버 엔드포인트를 제공합니다.
    uri: process.env.REACT_APP_HTTP_BACKEND_LINK // 환경 변수 사용
  // uri: 'https://3.36.70.64/graphql', // 여기에 실제 GraphQL 서버 엔드포인트를 제공합니다.
});

const authLink = setContext((_, { headers }) => {
  // 로컬 스토리지에서 JWT 토큰 가져오기
  const token = localStorage.getItem('authInfo') ? JSON.parse(localStorage.getItem('authInfo')).token : "";
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});


const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}> {/* 여기에 추가 */}
      <BrowserRouter>
        <ScrollTop />
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </BrowserRouter>
    </GoogleOAuthProvider> {/* 여기에 추가 */}
  </React.StrictMode>
);

reportWebVitals();
