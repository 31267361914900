import React from 'react';
import styled from 'styled-components';
import { ThreeDots } from 'react-loader-spinner';

// styled-components를 사용하여 스타일이 적용된 컴포넌트 생성
const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // 화면 높이 전체를 사용
  width: 100vw; // 화면 너비 전체를 사용
  position: fixed; // 필요에 따라 추가
  top: 0;
  left: 0;
`;

function CenteredLoading() {
  return (
    <CenteredContainer>
      <ThreeDots
        visible={true}
        height="40"
        width="40"
        color="#51ABF3"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </CenteredContainer>
  );
}

export default CenteredLoading;
